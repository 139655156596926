import { useFetcher, useMatches } from '@remix-run/react';
import { useContext, useEffect, useState } from 'react';

import VideoPlayer, { links as videoPlayerStyles } from '~/components/WebPlayer/VideoPlayer';
import handleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import globalContext from '~/contexts/globalContext';
import { encodeVTT } from '~/helper/captionsHelpers';
import { isFlagPresent } from '~/helper/helper';

import type { LinksFunction } from '@remix-run/node';
import type { Comment, FlipResponse, RouteTyping, Topic, VideoFocus } from 'types';

export const links: LinksFunction = () => {
  return [...videoPlayerStyles()];
};

export type BasicPlayerWrapperProps = {
  entity:
    | { item: VideoFocus; topic: Topic; type: 'TopicFocus' }
    | { item: Comment; topic?: never; type: 'Comment' }
    | {
        hasURLOption: (option: string) => boolean;
        item: FlipResponse;
        reportId: string;
        setShowControls: (value: boolean) => void;
        topic?: never;
        type: 'PrivateShare';
      };
  videoPlayerId?: string;
};

const BasicPlayerWrapper = ({ entity, videoPlayerId }: BasicPlayerWrapperProps) => {
  const { item, topic, type } = entity;
  const fetcher = useFetcher<string | number | boolean>();

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');
  const markAsViewedFetcher = useFetcher();

  const [transcript, setTranscript] = useState('');
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === handleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const isMobile = ua?.mobile;
  const inIframe = ua?.inIframe;

  useEffect(() => {
    if (item.transcript) {
      const qs = new URLSearchParams();

      if (type === 'Comment') {
        qs.append('id', item.id.toString());
        qs.append('group_id', item.grid_id.toString());
        qs.append('_action', 'commentTranscript');
      } else if (type === 'TopicFocus') {
        qs.append('id', topic.id.toString());
        qs.append('group_id', topic.grid_id.toString());
        qs.append('_action', 'topicTranscript');
      } else if (type === 'PrivateShare') {
        qs.append('id', item.id.toString());
        qs.append('token', item.vanity_token);
        if (entity.reportId) qs.append('reportId', entity.reportId);
        qs.append('_action', 'privateShareTranscript');
      }

      fetcher.load(resourceRoutes.transcripts + `?${qs}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetcher.type === 'done' && fetcher.data) {
      setTranscript(encodeVTT(fetcher.data));
    }
  }, [fetcher]);

  const markAsViewed = () => {
    const formData = new FormData();
    formData.append('_action', type);

    if (type === 'Comment') {
      formData.append('id', item.id.toString());
      formData.append('groupId', item.grid_id.toString());
    } else if (type === 'TopicFocus') {
      formData.append('id', topic.id.toString());
      formData.append('groupId', topic.grid_id.toString());
    } else if (type === 'PrivateShare') {
      formData.append('id', entity.reportId.toString());
      formData.append('token', item.vanity_token);
    }

    if (!isDeprecated) markAsViewedFetcher.submit(formData, { method: 'post', action: resourceRoutes.markAsViewed });
  };

  return (
    <VideoPlayer
      captionsSrc={transcript}
      initialDuration={item.video_length}
      markAsViewed={markAsViewed}
      poster={item.image_url}
      src={type === 'Comment' || type === 'PrivateShare' ? item.video_url || '' : item.resource}
      autoPlay={entity.type === 'PrivateShare' ? !inIframe || entity.hasURLOption('autoplay') : false}
      messaging={entity.type === 'PrivateShare' ? entity.hasURLOption('messaging') : false}
      onControlsHidden={entity.type === 'PrivateShare' ? () => entity.setShowControls(false) : undefined}
      onControlsShown={entity.type === 'PrivateShare' ? () => entity.setShowControls(true) : undefined}
      videoPlayerId={videoPlayerId}
    >
      {!isMobile && (
        // @ts-ignore - The VideoPlayer layers props onto this but TS doesn't know about it
        <VideoPlayer.Controls
          entity={entity}
          autoFocus={type === 'PrivateShare' ? !inIframe : true}
          disableFullscreen={entity.type === 'PrivateShare' ? entity.hasURLOption('disable-fullscreen') : false}
        />
      )}
    </VideoPlayer>
  );
};

export default BasicPlayerWrapper;
